import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

const TermsOfService: React.FC = () => {
  return (
    <Container data-testid="terms-of-service">
      <iframe
        src="https://app.termly.io/policy-viewer/policy.html?policyUUID=c17e925c-e93a-4f9b-889a-2639a571a79e"
        title="Terms of Service"
        style={{
          width: '100%',
          height: '100vh',
          border: 'none',
        }}
      />
    </Container>
  );
};

export default TermsOfService;