import React from 'react';

const SignupContainer: React.FC = (): JSX.Element => {
  const openMailchimpSignup = (e: React.MouseEvent): void => {
    e.preventDefault();
    window.open('http://eepurl.com/i1HkSA', 'MailchimpSignup', 'height=700,width=600');
  };

  return (
    <div className="signup-container">
      <h2 className="signup-title">Sign up for Beta Access</h2>
      <button onClick={openMailchimpSignup} className="button">
        Join Beta
      </button>
    </div>
  );
};

export default SignupContainer;