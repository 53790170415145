import React from 'react';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import './IPhoneMock.css';

interface IPhoneMockProps {
  name: string;
  imageUrl: string;
}

const IPhoneMock: React.FC<IPhoneMockProps> = ({ name, imageUrl }) => (
  <div className="iphone-mock-container">
    <div className="iphone-mock">
      <DeviceFrameset device="iPhone X" color="black">
        <div className="content-container">
          <img src={imageUrl} alt={`Kai Social ${name}`} className="image" />
        </div>
      </DeviceFrameset>
    </div>
  </div>
);

export default IPhoneMock;